import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/products/orion360sdk/orion360_sdk_background.jpg";
import image2 from "assets/img/products/orion360sdk/orion360_sdk_map.jpg";
import image3 from "assets/img/products/orion360sdk/orion360_sdk_box.jpg";

import logo from "assets/img/products/orion360sdk/orion360_sdk_logo.png";

import Aftonbladet from "assets/img/logos/aftonbladet-480x480.png";
import BBCRnD from "assets/img/logos/bbc_r_d-480x480.png";
import DiscoveryVR from "assets/img/logos/discoveryvr-480x480.png";
import Lexus from "assets/img/logos/lexus-480x480.png";
import Littlstar from "assets/img/logos/littlstar-480x480.png";
import UsaToday from "assets/img/logos/usatoday-480x480.png";

class Orion360Section extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Orion360™ SDK</h2>
              <h4>License our SDK and build your own 360/VR video apps that can stitch, play, annotate, or analyze videos.</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Product",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>

                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Orion360™ SDK powers hundreds of 360/VR video apps on iOS, Android, Oculus,
                          Linux, Windows, and Windows Embedded platforms. Our customers use it on
                          mobiles, VR headsets, 360° cameras, desktop computers, servers, cloud,
                          and heavy working machines. The core of the Orion360 video engine is written
                          in C++ and we have built adaptation layers for example for Java, Objective-C,
                          and Windows.
                        </p>
                        <p>
                          Orion360™ SDK can be used for building an entire VR app including a 3D user
                          interface, as you can create selectable 3D UI components and use view direction
                          or hand remotes for selection and item manipulation. On the other hand, using
                          Orion360™ is particularly lucrative for cases where an app already exists and 
                          you just need to add a versatile and capable 360°/VR player component. There is
                          no need to learn Unity or 3D graphics; regular app development skills are enough.
                        </p>
                        <p>
                          Orion360™ video engine goes far beyond traditional video players, as
                          advanced features such as multi-camera feeds with custom lens profiles,
                          equirectangular and littleplanet projections, sensor fusion based control,
                          animated 3D objects with 3D point & click, view direction tracking, etc.
                          are first-class citizens. And, if you need a feature or integration that
                          does not exist yet, we will build it for you.
                        </p>
                        <p>
                          Orion360™ SDK is publicly available for iOS and Android app development via
                          our GitHub repositories. Other versions are available on request. You can
                          either license the SDK and build an app with your own team, or hire our
                          experts to build the entire app or a part of it for you or to assist your 
                          own team in technical issues and best practices.
                        </p>
                        <p>
                          <b>
                            Contact us to discuss about your SDK needs, or try out iOS and Android
                            versions via the links below.
                          </b>
                        </p>
                        <Button
                            round
                            color="success"
                            size="md"
                            href="contact-page/"
                            target="_self"
                            rel="noopener noreferrer"
                          >
                            Contact us
                        </Button>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={3} sm={3} md={2}>
                            <img
                              src={logo}
                              alt="Orion360 SDK"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={9} sm={9} md={4} className={classes.marginAuto}>
                            <h4>Orion360™ SDK for Android</h4>
                            <p className={classes.textCenter}>
                              <a href="https://github.com/finweltd/orion360-sdk-pro-hello-android">Hello World</a>
                            </p>
                            <p className={classes.textCenter}>
                              <a href="https://github.com/FinweLtd/orion360-sdk-pro-examples-android">SDK Examples</a>
                            </p>
                          </GridItem>
                          <GridItem xs={3} sm={3} md={2}>
                            <img
                              src={logo}
                              alt="Orion360 SDK"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={9} sm={9} md={4} className={classes.marginAuto}>
                            <h4>Orion360™ SDK for iOS</h4>
                            <p className={classes.textCenter}>
                              <a href="https://github.com/finweltd/orionsdk_ios_prd">Hello World</a>
                            </p>
                            <p className={classes.textCenter}>
                              <a href="https://github.com/FinweLtd/Orion_SDK_iOS_SampleApps">SDK Examples</a>
                            </p>
                          </GridItem>                          
                        </GridContainer>
                        <GridContainer>

                        </GridContainer>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <h4>A few examples of customers who built their apps using Orion360™ SDK:</h4>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={Aftonbladet}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={BBCRnD}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={DiscoveryVR}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={Lexus}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={Littlstar}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={3} sm={2} md={2} className={classes.marginAuto}>
                            <img
                              src={UsaToday}
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(Orion360Section);
